import React from 'react';

export function AccessDenied (props) {
    return (
			<div>
      	<h1>Access Denied</h1>
				<p>You do not have permissions to access this page.</p>
			</div>
    );
};
