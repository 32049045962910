export const DigiKeySites = {
	US: { value: 0, name: 'USA', flag: 'US' },
	CA: { value: 1, name: 'Canada', flag: 'CA' },
	JP: { value: 2, name: 'Japan', flag: 'JP' },
	UK: { value: 3, name: 'United Kingdom', flag: 'UK' },
	DE: { value: 4, name: 'Germany', flag: 'DE' },
	AT: { value: 5, name: 'Austria', flag: 'AT' },
	BE: { value: 6, name: 'Belgium', flag: 'BE' },
	DK: { value: 7, name: 'Denmark', flag: 'DK' },
	FI: { value: 8, name: 'Finland', flag: 'FI' },
	GR: { value: 9, name: 'Greece', flag: 'GR' },
	IE: { value: 10, name: 'Ireland', flag: 'IE' },
	IT: { value: 11, name: 'Italy', flag: 'IT' },
	LU: { value: 12, name: 'Luxembourg', flag: 'LU' },
	NL: { value: 13, name: 'Netherlands', flag: 'NL' },
	NO: { value: 14, name: 'Norway', flag: 'NO' },
	PT: { value: 15, name: 'Portugal', flag: 'PT' },
	ES: { value: 16, name: 'Spain', flag: 'ES' },
	KR: { value: 17, name: 'South Korea', flag: 'KR' },
	HK: { value: 18, name: 'Hong Kong', flag: 'HK' },
	SG: { value: 19, name: 'Singapore', flag: 'SG' },
	CN: { value: 20, name: 'China', flag: 'CN' },
	TW: { value: 21, name: 'Taiwan', flag: 'TW' },
	AU: { value: 22, name: 'Australia', flag: 'AU' },
	FR: { value: 23, name: 'France', flag: 'FR' },
	IN: { value: 24, name: 'India', flag: 'IN' },
	NZ: { value: 25, name: 'New Zealand', flag: 'NZ' },
	SE: { value: 26, name: 'Sweden', flag: 'SE' },
	MX: { value: 27, name: 'Mexico', flag: 'MX' },
	CH: { value: 28, name: 'Switzerland', flag: 'CH' },
	IL: { value: 29, name: 'Israel', flag: 'IL' },
	PL: { value: 30, name: 'Poland', flag: 'PL' },
	SK: { value: 31, name: 'Slovakia', flag: 'SK' },
	SI: { value: 32, name: 'Slovenia', flag: 'SI' },
	LV: { value: 33, name: 'Latvia', flag: 'LV' },
	LT: { value: 34, name: 'Lithuania', flag: 'LT' },
	EE: { value: 35, name: 'Estonia', flag: 'EE' },
	CZ: { value: 36, name: 'Czech Republic', flag: 'CZ' },
	HU: { value: 37, name: 'Hungary', flag: 'HU' },
	BG: { value: 38, name: 'Bangladesh', flag: 'BG' },
	MY: { value: 39, name: 'Malaysia', flag: 'MY' },
	ZA: { value: 40, name: 'South Africa', flag: 'ZA' },
	RO: { value: 41, name: 'Romania', flag: 'RO' },
	TH: { value: 42, name: 'Thailand', flag: 'TH' },
	PH: { value: 43, name: 'Philippines', flag: 'PH' },
};